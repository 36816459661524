import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { TabLinks } from '../../../components/TabLinks';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "data-visualization",
      "style": {
        "position": "relative"
      }
    }}>{`Data visualization`}</h1>
    <p>{`Data visualization consists of presenting information and data graphically to synthesizing complex information and understanding patterns and exceptions presented in the data. Data visualization should not be used as decoration.`}</p>
    <TabLinks items={[{
      title: 'Models',
      href: '/design-guidelines/data-visualization/models'
    }, {
      title: 'Usage',
      href: '/design-guidelines/data-visualization/usage'
    }]} mdxType="TabLinks" />
    <h3 {...{
      "id": "line-chart",
      "style": {
        "position": "relative"
      }
    }}>{`Line chart`}</h3>
    <p>{`Used to show trends over time and for comparing data series. It shows values at points connected by lines as continuous data.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/line-chart.png",
        "alt": "Line chart"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "/image/line-chart-multiple.png",
        "alt": "Multiple line chart"
      }}></img></p>
    <h4 {...{
      "id": "variation-area-chart",
      "style": {
        "position": "relative"
      }
    }}>{`Variation: Area chart`}</h4>
    <p>{`Show the same information as the line graph, only presenting it graphically.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/area-chart.png",
        "alt": "Area chart"
      }}></img></p>
    <h3 {...{
      "id": "bar-chart",
      "style": {
        "position": "relative"
      }
    }}>{`Bar chart`}</h3>
    <p>{`Uses bars to compare discrete, categorical data or to compare trends. Display bars vertically and corresponds to your numeric value measurement.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/bar-chart.png",
        "alt": "Bar chart"
      }}></img></p>
    <h3 {...{
      "id": "column-chart",
      "style": {
        "position": "relative"
      }
    }}>{`Column chart`}</h3>
    <p>{`In charts that are measured on nominal (qualitative) scales, display the bars in the horizontal position. This facilitates a quick view of categories, which can be identified next to or inside each bar.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/column-chart.png",
        "alt": "Column chart"
      }}></img></p>
    <h3 {...{
      "id": "stacked-chart",
      "style": {
        "position": "relative"
      }
    }}>{`Stacked chart`}</h3>
    <p>{`Used to compare the contribution ratio to the total value within a scale.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/stacked-chart.png",
        "alt": "Stacked column chart"
      }}></img></p>
    <h3 {...{
      "id": "pie-chart",
      "style": {
        "position": "relative"
      }
    }}>{`Pie chart`}</h3>
    <p>{`Used to show a part-to-whole visualization. By comparing data through angles, pie charts are not usually the easiest options for analyzing quantitative data (consider using Bar Chart or Column Chart). Pay attention to the number of items, as these charts do not accommodate large numbers of categories.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/circular-chart.png",
        "alt": "Pie chart"
      }}></img></p>
    <h3 {...{
      "id": "control-chart",
      "style": {
        "position": "relative"
      }
    }}>{`Control chart`}</h3>
    <p>{`Control charts are used in processes to track the evolution of a variable against one or more value references.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/control-line-chart.png",
        "alt": "Control line chart"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "/image/control-area-chart.png",
        "alt": "Control area chart"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      